// eslint-disable-next-line no-redeclare,no-unused-vars
function addPatternField(cont, param) {
  var object = param.object;

  var pm = {
    key: 'variant',
    label: param.label,
    field: 'select',
    field2: 'button',
    iconBtn: 'cog',
    field3: 'addNew',
    reference: object,
    objectSub: param.objectSub,
    adopt: {object: param.ref},
    view: param.view,
    labels: [],
    values: [],
  };
  if (param.value) {
    pm.value = param.value;
    param.callback(data[object][param.value]);
  }

  // select values
  if (param.patterns)
    $.each(param.patterns, function (i, id) {
      var row = data[object][id];
      pm.labels.push(row.title);
      pm.values.push(id);
    });

  // update editor text
  pm.save = function (id) {
    var row = data[object][id];
    param.callback(row);
  };

  // edit existing pattern
  pm.funcField2 = function () {
    var id = li.element.val();
    if (!id) return;
    ajax({
      object: object,
      objectSub: param.objectSub,
      id: id,
      modal: true,
      func: 'detail',
      afterClose: pm.afterClose,
    });
  };

  // update editor text + select values
  pm.afterClose = function (popup) {
    var row = data[object][popup.id];
    if (!inArray(row.id, pm.values)) {
      li.element.add(row.id, row.short);
      li.element.val(row.id);
    }
    param.callback(row);
  };

  var li = buildFormLine(cont, pm);
  return li;
}
